<vex-layout [usuario]="usuario">
    <router-outlet></router-outlet>
</vex-layout>







