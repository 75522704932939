
import { VexRoutes } from "@vex/interfaces/vex-route.interface";

export const appRoutes: VexRoutes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/listar-plano-operacao/listar-plano-operacao.component').then(
        (m) => m.ListarPlanoOperacaoComponent
      )
  },
  {
    path: 'cadastrar',
    loadComponent: () =>
      import('./pages/cadastrar-plano-operacao/cadastrar-plano-operacao.component').then(
        (m) => m.CadastrarPlanoOperacaoComponent
      )
  }
  // {
  //   path: '',
  //   loadComponent: () =>
  //     import('./pages/listar-plano-operacao/listar-plano-operacao.component').then(
  //       (m) => m.ListarPlanoOperacaoComponent
  //     )
  // },
];
