import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { Usuario } from '../models/usuario.model';

export const OAUTH_CONFIG: AuthConfig = {
    issuer: `${environment.sso_url}`,
    redirectUri: `${environment.sso_redirect_uri}`,
    clientId: `${environment.sso_client_id}`,
    responseType: 'code',
    showDebugInformation: false,
    scope: 'openid profile',
    requireHttps: false,
};

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    user: Subject<Usuario> = new Subject<Usuario>();

    constructor(private oauthService: OAuthService) {}

    login(): void {
        this.oauthService.configure(OAUTH_CONFIG);
        this.oauthService.setStorage(localStorage);
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
            this.oauthService.tryLogin().then(() => {
                if (!this.oauthService.hasValidAccessToken()) {
                    this.oauthService.initLoginFlow();
                } else {
                    this.oauthService.setupAutomaticSilentRefresh();
                    this.oauthService.loadUserProfile().then((up:any) => {
                      console.log(up.info);
                      // let posicao = up?.info?.indexOf(' ');
                        this.user.next({
                          name: up?.info?.given_name?.substring(8).trim(),
                          cpf: up?.info?.given_name?.replace(0, 8).trim(),
                        });
                    }).catch(reason => {
                        console.log("LoadUserProfile failed");
                        console.log(reason);
                        this.oauthService.initLoginFlow();
                    });
                }
            }).catch(reason => {
                console.log("TryLogin failed");
                console.log(reason);
                this.oauthService.initLoginFlow();
            });
        }).catch(reason => {
            console.log("LoadDiscoveryDocumentAndTryLogin failed");
            console.log(reason);
            this.oauthService.initLoginFlow();
        });
    }

    getUser(): Observable<Usuario> {
        return this.user;
    }

    logout() {
        this.oauthService.logOut();
    }
}
